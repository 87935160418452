@font-face {
    font-family: Satoshi-light;
    src: url(../../../../public/Satoshi-Light.otf);
}

@font-face {
    font-family: Satoshi;
    src: url(../../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-med;
    src: url(../../../../public/Satoshi-Medium.otf);
}

.ContactForm-container {
    padding: 0% 3.2%;
    overflow: hidden;
    padding-top: 2.5%;
}

.ContactForm-container-row-col-1 {
    width: 60%;
    position: relative;
}

.ContactForm-container-row-col-2 {
    width: 40%;
}

.ContactForm-h6 {
    color: var(--text-icon-light-heading, #131A27);
    font-family: Satoshi;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    width: 45%;
}

.ContactForm-field-container {
    margin-bottom: 3.5%;
}

.ContactForm-field-lable {
    color: var(--Grey-500, #667085);
    font-family: Satoshi;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.2px;
    margin-bottom: 1%;
}

.ContactForm-field-input,
.ContactForm-field-textarea {
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--Grey-300, #D0D5DD);
    height: 80px;
    outline: none;
    font-size: 20px;
    padding-left: 3%;
}

.ContactForm-field-textarea {
    padding-top: 2%;
    height: 129px;
}

.ErrorMessage {
    color: red;
    font-size: large;
}

.ContactToast {
    position: absolute;
    width: fit-content;
    transform: translate(20vw, 10vw);
}

.ContactToastBody {
    width: fit-content;
}

@media (max-width:1696px) {
    .ContactForm-h6 {
        width: 50%;
    }
}

@media (max-width:1528px) {
    .ContactForm-h6 {
        width: 55%;
    }
}

@media (max-width:1440px) {
    .ContactForm-field-input {
        height: 50px;
    }

    .ContactForm-field-textarea {
        height: 129px;
    }

    .ContactForm-h6 {
        width: 75%;
    }
}

@media (max-width:1388px) {
    .ContactForm-h6 {
        width: 85%;
    }
}

@media (max-width:1024px) {
    .ContactForm-h6 {
        font-size: 20px;
        width: 60%;
    }

    .ContactForm-field-input {
        height: 45px;
    }

    .ContactForm-field-textarea {
        height: 110px;
    }
}

@media (max-width:768px) {
    .ContactForm-container-row-col-1 {
        width: 100%;
    }

    .ContactForm-container-row-col-2 {
        width: 90%;
        margin-top: 20px;
    }

    .ContactForm-field-input {
        height: 40px;
        font-size: 17px;
    }

    .ContactForm-field-textarea {
        height: 110px;
    }

    .ContactForm-h6 {
        font-size: 17px;
    }

    .ContactForm-field-lable {
        font-size: 17px;
    }

    .ContactToast {

        background-color: transparent;
        transform: none;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-right: 7%;
        transform: translateY(30vw);
    }
}

@media (max-width:600px) {
    .ContactForm-container-row-col-1 {
        width: 100%;
    }

    .ContactForm-container-row-col-2 {
        width: 100%;
        margin-top: 20px;
    }
}

@media (max-width:425px) {

    .ContactForm-container-row-col-1 {
        width: 100%;
    }

    .ContactForm-container-row-col-2 {
        width: 100%;
        margin-top: 20px;
    }

    .ContactForm-field-input {
        height: 40px;
        font-size: 17px;
    }

    .ContactForm-field-textarea {
        height: 110px;
    }

    .ContactForm-h6 {
        font-size: 17px;
    }

    .ContactForm-field-lable {
        font-size: 16px;
    }

    .ContactForm-h6 {
        font-size: 15px;
        width: 80%;
    }
}