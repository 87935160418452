@font-face {
  font-family: Satoshi;
  src: url(../../../../public/Satoshi-Regular.otf);
}

@font-face {
  font-family: Satoshi-med;
  src: url(../../../../public/Satoshi-Medium.otf);
}

.accordion .accordion-item {
  padding: 0;
  border-bottom: 1px solid var(--Grey-400, #98a2b3);
  background: var(--BgColors-Three, #f7f5f2);
}

.accordion {
  background: none;
  max-width: 1792px !important;
  --bs-accordion-border-color: none;
}

.career-page-accordian-section {
  margin-top: 2rem;
}

.accordion-button {
  color: var(--text-icon-light-body, #33353a);
  font-family: Satoshi-med;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  height: 10vh;
  background: var(--BgColors-Three, #f7f5f2);
  padding: 8px;
}

.accordion-heading {
  color: var(--Base-Black, #000);
  background: var(--BgColors-Three, #f7f5f2);
  font-family: Satoshi-med;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.24px;
}

.accordion-para {
  color: var(--text-icon-light-body, #33353a);
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.2px;
  max-width: 1609px;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #000;
}

/* Override Bootstrap's default focus color */
.accordion-button:not(.collapsed):focus {
  box-shadow: none;
}

/* Override Bootstrap's default focus color for collapsed accordion items */
.accordion-button:focus {
  box-shadow: none !important;
}

/* Override Bootstrap's default hover color */
.accordion-button:not(.collapsed):hover {
  background-color: transparent;
}

/* Responsive Text Size */
@media (max-width: 768px) {
  .accordion-button {
    font-size: 23px;
  }

  .accord-heading {
    font-size: 20px;
  }

  .accordion-heading {
    font-size: 20px;
  }

  .accordion-para {
    font-size: 16px;
  }

  .career-page-accordian-section {
    margin-top: 0rem;
  }
}