@font-face {
    font-family: Satoshi-light;
    src: url(../../../../public/Satoshi-Light.otf);
}

@font-face {
    font-family: Satoshi;
    src: url(../../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-med;
    src: url(../../../../public/Satoshi-Medium.otf);
}

.MembersContainer {
    width: 100%;
    overflow: hidden;
}

.MembersContainer-Row {
    justify-content: space-between;
}

.Member {
    border: 0px solid;
    margin: 50px 0px 25px;
    width: 16.7%;
}

.Member-Row {
    justify-content: center;
    align-content: center;
}

.MemberImage {
    height: 230px;
    width: 230px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100%;
    border-radius: 500px;
    background-color: #F4E9E1;
    transition: 0.2s;
}

.Member-name {
    color: var(--text-icon-light-heading, #131A27);
    font-family: Satoshi-med;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    margin-top: 1rem;
}

.Member-teamName {
    color: var(--text-icon-light-body, #33353A);
    text-align: center;
    font-family: Satoshi;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.24px;
}

@media (max-width:1481px) {
    .Member {
        width: 25%;
    }
}

@media (max-width:1265px) {
    .Member {
        width: 25%;
    }
}

@media (max-width:1024px) {
    .Member {
        border: 0px solid;
        margin: 30px 0px 20px;
        width: 22%;
    }

    .MemberImage {
        height: 200px;
        width: 200px;
    }

    .Member-name {
        font-size: 30px;
        padding-top: 10px;
    }

    .Member-teamName {
        font-size: 20px;
    }
}

@media (max-width:955px) {
    .MemberImage {
        height: 180px;
        width: 180px;
    }
}

@media (max-width:896px) {
    .Member {
        border: 0px solid;
        margin: 40px 0px;
        width: 22%;
    }

    .MemberImage {
        height: 150px;
        width: 150px;
    }

    .Member-name {
        font-size: 27px;
        padding-top: 10px;
    }

    .Member-teamName {
        font-size: 18px;
    }

}

@media (max-width:768px) {
    .Member {
        border: 0px solid;
        margin: 30px 0px 20px;
        width: 30%;
    }

    .MemberImage {
        height: 180px;
        width: 180px;
    }

    .Member-name {
        font-size: 27px;
        padding-top: 10px;
    }

    .Member-teamName {
        font-size: 17px;
    }
}

@media (max-width:600px) {
    .Member {
        border: 0px solid;
        width: 30%;
    }

    .MemberImage {
        height: 150px;
        width: 150px;
    }

    .Member-name {
        font-size: 20px;
        padding-top: 5px;
    }

    .Member-teamName {
        font-size: 15px;
    }
}

@media (max-width:537px) {
    .Member {
        border: 0px solid;
        width: 50%;
    }

    .MemberImage {
        height: 200px;
        width: 200px;
    }

    .Member-name {
        font-size: 20px;
        padding-top: 5px;
    }

    .Member-teamName {
        font-size: 15px;
    }

}

@media (max-width:425px) {
    .Member {
        border: 0px solid;
        margin: 25px 0px 20px;
        width: 50%;
    }

    .MemberImage {
        height: 170px;
        width: 170px;
    }

    .Member-name {
        font-size: 20px;
        padding-top: 5px;
    }

    .Member-teamName {
        font-size: 15px;
    }
}

@media (max-width:375px) {
    .Member {
        border: 0px solid;
        width: 50%;
    }

    .MemberImage {
        height: 150px;
        width: 150px;
    }

    .Member-name {
        font-size: 20px;
        padding-top: 5px;
    }

    .Member-teamName {
        font-size: 15px;
    }
}

@media (max-width:320px) {
    .Member {
        border: 0px solid;
        width: 50%;
    }

    .MemberImage {
        height: 120px;
        width: 120px;
    }

    .Member-name {
        font-size: 18px;
        padding-top: 5px;
    }

    .Member-teamName {
        font-size: 13px;
    }
}