@font-face {
    font-family: Satoshi;
    src: url(../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-medium;
    src: url(../../../public/Satoshi-Medium.otf);
}

.header-section {
    text-align: center;
    display: flex;
    justify-content: center;
    overflow: hidden;

}

.nav-Container {
    position: fixed;
    bottom: 2.5%;
    border-radius: 50px;
    overflow: hidden;
    background-color: transparent;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(20px);
}

.nav-items-section {
    display: flex;
    gap: 1vh;
    border-radius: 50px;
    opacity: 0.95;
    background-color: rgba(0, 0, 0, 0.185);
    color: #fff;
    padding: 1.2rem 1.8rem;
    z-index: 1000;
}

.nav-items {
    background-color: transparent;
    cursor: pointer;
    font-family: Satoshi;
    font-size: 20px;
    font-weight: 100;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .5s;
    padding: 0.5rem;
}

.header-section:hover .flashlight {
    width: 40px;
    height: 40px;
}

.flashlight {
    filter: blur(19px);
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 30px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: all 0.5s ease-out;
    box-shadow: 0px 0px 0px 0px white;
    transform: translate(-100px, -100px);
}

.smallBall {
    position: absolute;
    transform: translate(30px, 40px);
    background-color: white;
    height: 8px;
    width: 8px;
    border-radius: 50px;
    transition: all 0.3s ease-in;
    transition-delay: 0.1s;
    display: block;
}

/* Responsive media queries */
@media only screen and (max-width: 1100px) {
    .nav-items-section {
        bottom: 1.5%;
        border-radius: 100px;
    }
}

@media only screen and (max-width: 768px) {
    .active {
        position: relative;
    }

    .contact-us-text-home {
        font-size: 22px;
    }

    .active::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translateX(-50%);
        width: 7px;
        height: 7px;
        background-color: #fff;
        border-radius: 50%;
        display: block;
    }

    .smallBall {
        display: none;
    }
}

@media only screen and (max-width: 550px) {
    .nav-items-section {
        bottom: 1.5%;
        border-radius: 100px;
    }
}

@media only screen and (max-width: 500px) {
    .nav-items {
        font-size: 15px;
    }

    .nav-items-section {
        gap: 0px;
        padding: 1.2rem 1.5rem;
    }
}

@media only screen and (max-width: 370px) {
    .nav-items {
        font-size: 13px;
    }

    .nav-items-section {
        padding: 1.2rem 1rem 1rem;
    }
}

@media only screen and (max-width: 300px) {
    .nav-items-section {
        width: 80%;
    }

    .nav-items {
        font-size: 10px;
    }

    .nav-items-section {
        padding: 1.2rem 0.5rem 1rem;
    }
}