@font-face {
    font-family: Satoshi;
    src: url(../../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-medium;
    src: url(../../../../public/Satoshi-Medium.otf);
}

.reviews-section {
    font-family: Satoshi-medium;
    margin: 6rem 0rem 8rem 4rem;
}

.reviews-heading {
    font-family: Satoshi-medium;
    font-size: calc(1.75rem + 1.5vw);
    text-align: center;
    margin-bottom: 3rem;
}

.reviews-text-sec {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.reviews-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38rem;
    margin: 0 auto;
}

.reviews-para {
    background-color: transparent;
    color: #33353A;
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.16px;
    margin-top: 1rem;
    align-items: center;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.text-arrows-container {
    display: flex;
    flex-direction: column;
    height: 29rem;
    justify-content: space-between;
}

.review-readtext {
    color: #004599;
    font-family: Satoshi;
    font-size: 20px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.2px;
    cursor: pointer;
}

.reviewcard-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: transparent;
    margin: 0rem 1rem;
}

.review-cards {
    border: none;
    background: transparent;
    width: 30rem;
    height: 29rem;
    border-radius: 25px;
}

.review-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}

.reviewcard-heading {
    padding: 0rem 1rem 0rem 0rem;
    background-color: transparent;
}

.review-client {
    color: #131A27;
    font-family: Satoshi-medium;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    background-color: transparent;
}

.review-place {
    color: #33353A;
    font-family: Satoshi;
    font-size: calc(1.2rem + 0.6vw);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.24px;
    background-color: transparent;
}

.reviews-carousel {
    overflow: hidden;
    scroll-behavior: smooth;
    cursor: grab;
}

.carousel-reviews {
    display: flex;
}

.reviews-arrows {
    display: flex;
    background-color: transparent;
}

.reviews-arrow {
    border: 1px solid black;
    border-radius: 50%;
    padding: 1.2vh 1.4vh;
    margin: 5px 10px;
    cursor: pointer;
    background-color: transparent;
}

.disabled-icon1 {
    color: rgba(140, 140, 140, 0.697);
    border-color: rgba(140, 140, 140, 0.697);
    cursor: default;
    background-color: transparent;
}

.disabled-icon1:hover {
    background-color: rgba(140, 140, 140, 0.697) !important;
    color: white;
    border-color: rgba(140, 140, 140, 0.697) !important;
}

.reviews-web-view {
    margin: 0px;
}

@media (max-width: 1200px) {
    .reviews-section {
        margin: 120px 2rem;
    }

    .reviews-text {
        width: 26rem;
        padding-right: 0rem;
    }
}

@media (max-width: 1000px) {
    .reviews-section {
        margin: 40px 1rem 100px;
    }

    .reviews-web-view {
        flex-direction: column;
    }

    .text-arrows-container {
        display: block;
        height: auto;
    }

    .reviews-text {
        width: 100%;
        display: block;
    }

    .review-cards {
        margin-top: 2rem;
        height: 30rem;
    }

    .reviews-para,
    .review-readtext {
        font-size: large;
    }
}

@media (max-width: 545px) {
    .review-cards {
        width: 25rem;
    }

    .reviews-para,
    .review-place {
        font-size: calc(1rem + 0.5vw);
    }
}

@media (max-width: 460px) {
    .review-cards {
        width: 22rem;
        height: 25rem;
    }

    .reviewcard-container {
        margin: 0rem;
    }

    .reviewcard-heading {
        padding: 0rem;
    }

    .reviews-section {
        margin: 3rem 0.5rem 0rem;
    }

    .review-client {
        font-size: 25px;
    }
}

@media (max-width: 385px) {
    .review-cards {
        width: 20rem;
        height: 22rem;
    }
}

@media (max-width: 345px) {
    .review-cards {
        width: 17rem;
    }
}