.carousel {
    cursor: grab;
    overflow: hidden;
}

.section-container {
    display: flex;
    gap: 20px;
    width: 100%;
}

.category-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 16px;
    padding: 20px 20px 70px;
    width: 728px;
    height: 630px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
}

.category-card h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    background-color: transparent;
}

.tag {
    border-radius: 24px;
    border: 1px solid #667085;
    padding: 10px 20px;
    background: transparent;
    color: #33353A;
    font-family: Satoshi;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.2px;
}

.tech-sstack-heading {
    color:  #33353A;
    font-family: Satoshi;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    border-radius: 12px;
    background: var(--Base-White, #FFF);
    padding: 16px 14px 10px;
}

