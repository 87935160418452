@font-face {
    font-family: Satoshi-med;
    src: url(../../../../public/Satoshi-Medium.otf);
}

.WorkFlowHover-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3% 10%;
}

.WorkFlowHover-Row-Container {
    width: 100%;
    border: 1px solid;
    background-color: var(--Grey-800, #1D2939);
    overflow: hidden;
    border-radius: 32px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.WorkFlowHover-row-col {
    width: 50%;
    background-color: var(--Grey-800, #1D2939);
}

.WorkFlowHover-h4 {
    background-color: var(--Grey-800, #1D2939);
    color: var(--text-icon-dark-heading, #FCFCFC);
    font-family: Satoshi-med;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.WorkFlowHover-h4-number {
    background-color: var(--Grey-800, #1D2939);
    color: var(--text-icon-dark-heading, #FCFCFC);
    font-family: Satoshi-med;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-align: right;
}

.WorkFlowHover-Background {
    background-color: var(--Grey-800, #1D2939);
    padding: 3% 5%;
    padding-top: 5%;
}

.WorkFlowHover-row {
    background-color: var(--Grey-800, #1D2939);
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
    overflow: hidden;
    border-top: 1px solid var(--BgColors-Three, #F7F5F2);
}

.WorkFlowHover-row:nth-child(1) {
    border-top: 0px solid var(--BgColors-Three, #F7F5F2);
}

.WorkFlowHover-Toogle {
    padding: 0% 5%;
}

.WorkFlowHover-Toogle {
    background-color: var(--Grey-800, #1D2939);
}

.WorkFlowHover-p {
    background-color: var(--Grey-800, #1D2939);
    color: white;
    width: 60%;
}

.WorkFlowHover-Toogle-height {
    height: 0px;
    transition: height 0.5s;
}

@media (max-width: 1600px) {
    .WorkFlowHover-p {
        width: 75%;
    }
}

@media (max-width:927px) {
    .WorkFlowHover-p {
        background-color: var(--Grey-800, #1D2939);
        color: white;
        width: 100%;
    }
}

@media (max-width:768px) {

    .WorkFlowHover-h4,
    .WorkFlowHover-h4-number {
        font-size: 4vw;
    }

    .WorkFlowHover-Container {
        padding: 3% 3%;
    }

    .WorkFlowHover-Row-Container {
        border-radius: 16px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .WorkFlowHover-row {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
    }    
    .WorkFlowHover-p{
        font-size: 16px !important;
    }
}

@media (max-width:425px) {

    .WorkFlowHover-h4,
    .WorkFlowHover-h4-number {
        font-size: 7vw;
    }
}