.slider {
    overflow: hidden;
    margin: 6rem 0rem 0rem;
    scroll-behavior: smooth;
}

.slide-track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8rem;
    animation: scroll 90s linear infinite;
}

.client-logo-img {
    opacity: 95%;
}

.logos-gradient {
    width: 222px;
    height: 100px;
    z-index: 1;
    position: absolute;
    background: linear-gradient(90deg, #F7F5F2 0%, rgba(247, 245, 242, 0.92) 33%, rgba(247, 245, 242, 0.00) 69.61%);
}

.logos-gradient1 {
    width: 251px;
    height: 150px;
    z-index: 1;
    right: 0;
    position: absolute;
    background: linear-gradient(90deg, #F7F5F2 0%, rgba(247, 245, 242, 0.92) 33%, rgba(247, 245, 242, 0.00) 69.61%);
    transform: rotate(180deg);
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-200px * 52));
    }
}

/* Responsive media queries */
@media only screen and (max-width: 1040px) {
    .slider {
        margin: 6rem 0rem 0rem;
    }

    .slide-track {
        gap: 5rem;
    }
}

@media only screen and (max-width: 800px) {
    .slider {
        margin: 5rem 0rem 0rem;
    }


    .logos-gradient {
        width: 150px;
    }

    .logos-gradient1 {
        width: 150px;
    }

    .slide-track {
        gap: 4rem;
    }

}