@font-face {
    font-family: Satoshi-medium;
    src: url(../../../public/Satoshi-Medium.otf);
}

@font-face {
    font-family: Satoshi-web;
    src: url(../../../public/Satoshi-Light.otf);
}

.footer-section {
    font-family: Satoshi-web;
    padding: 4rem 5rem 9rem;
    background: var(--Grey-800, #1D2939);
}

.last-section,
.last-section-two {
    display: flex;
    justify-content: space-between;
    gap: 1vh;
    background-color: transparent;
    margin-top: 3rem;
}

.last-section-two {
    display: none;
}

.footer-section-privacy-text {
    margin-top: 1.5%;
}

.footer-section-privacy-text,
.footer-section-privacy-text1 {
    background-color: transparent;
    display: flex;
    gap: 0.6rem;
    align-items: center;
}

.footer-section-privacy-text1 {
    gap: 1.5rem;
}

.last-section1 {
    display: flex;
    justify-content: space-between;
    gap: 10vh;
    background-color: transparent;
}

.last-section2 {
    display: flex;
    justify-content: space-between;
    gap: 10vh;
    background-color: transparent;
    margin-left: 20%;
}

.last-section3 {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    margin-top: 1.5rem;
}

.last-text,
.last-text1 {
    background-color: transparent;
    color: #FCFCFC;
    font-family: Satoshi-web;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 150%;
}

.last-text1 {
    cursor: pointer;
}

.last-text1:hover {
    text-decoration: underline
}

.footer-section-color {
    background-color: transparent;
}

.footer-section-color {
    background-color: transparent;
}

.footer-section-text1 {
    font-family: Satoshi-medium;
    background-color: transparent;
    color: #FCFCFC;
    font-size: calc(1.1rem + 0.85vw);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.footer-section-text2 {
    background-color: transparent;
    color: #FCFCFC;
    font-size: calc(0.65rem + 0.7vw);
    line-height: 150%;
    letter-spacing: 1px;
    margin: 10px 0px 0px;
    font-weight: 100;
    font-family: Satoshi-web;
}

.footer-section-text3 {
    background-color: transparent;
    color: #FCFCFC;
    font-size: calc(1.1rem + 0.85vw);
    font-weight: 500;
    line-height: 120%;
    margin: 0px;
    margin-top: 0.8rem;
    cursor: pointer;
}

.footer-section-text4 {
    background-color: transparent;
    color: #FCFCFC;
    font-size: calc(1.1rem + 0.85vw);
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 0px;
    margin-top: 1rem;
}

.social-icon-img,
.social-icon-img1 {
    background-color: transparent;
    width: 1.8rem;
}

.footer-section-icon {
    background-color: transparent;
    padding-left: 1rem;
    font-size: 2.9rem;
}

.footer-section-icon:hover {
    color: rgb(245, 160, 145);
}

/* Responsive media queries */
@media only screen and (max-width: 1960px) {
    .last-section2 {
        margin-left: 10%;
    }
}

@media only screen and (max-width: 1760px) {
    .last-section2 {
        margin-left: 5%;
    }
}

@media only screen and (max-width: 1675px) {
    .last-section2 {
        margin-left: 0%;
        gap: 3vh;
    }

    .footer-section {
        padding: 4rem 2rem 8rem;
    }
}

@media only screen and (max-width: 1450px) {
    .last-section1 {
        gap: 3vh;
    }
}


@media only screen and (max-width: 768px) {
    .footer-section-icon {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 700px) {
    .last-section1 {
        display: block;
    }

    .footer-section-text2 {
        width: 80%;
    }

    .last-section2 {
        margin-top: 1rem;
        margin: 2rem 0rem;
    }

    .social-icon-img,
    .social-icon-img1 {
        margin-right: 1rem;
    }

    .last-section3 {
        gap: 3vh;
    }

    .last-section2 {
        display: block;
    }

    .temp-col-temp {
        display: none;
    }

    .last-text,
    .last-text1 {
        font-size: 12px;
    }

    .last-section {
        display: none;
    }

    .last-section-two {
        display: block;
    }

    .footer-section-privacy-text1 {
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 480px) {
    .last-section3 {
        display: block;
    }

    .footer-section {
        padding: 4rem 1rem 7rem;
    }

    .social-icon-img1 {
        width: 2.3rem;
    }

    .last-text,
    .last-text1 {
        font-size: 10px;
    }

    .footer-section-privacy-text {
        gap: 0.3rem;
    }
}