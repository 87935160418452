@font-face {
    font-family: Satoshi-light;
    src: url(../../../../public/Satoshi-Light.otf);
}

@font-face {
    font-family: Satoshi;
    src: url(../../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-med;
    src: url(../../../../public/Satoshi-Medium.otf);
}

.ApplyNowNav-container {
    padding: 0% 3.2%;
    overflow: hidden;
}

.ApplyNowNav-col-1 {
    width: 70%;
}

.ApplyNowNav-col-2 {
    width: 30%;
}

.ApplyNowNav-h5 {
    color: var(--text-icon-light-heading, #131A27);
    font-family: Satoshi-med;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.ApplyNowNav-button-background {
    text-align: right;
    margin-top: 10px;
}

@media (max-width:1024px) {
    .ApplyNowNav-h5 {
        font-size: 38px;
    }
}

@media (max-width:768px) {
    .ApplyNowNav-h5 {
        font-size: 30px;
    }
    .about-page-br-css{
        display: none;
    }
}

@media (max-width:600px) {
    .ApplyNowNav-h5 {
        font-size: 30px;
    }

    .ApplyNowNav-col-1 {
        width: 100%;
    }

    .ApplyNowNav-col-2 {
        width: 100%;

    }

    .ApplyNowNav-button-background {
        text-align: center;
    }
}

@media (max-width:425px) {
    .ApplyNowNav-h5 {
        font-size: 28px;
    }
}

@media (max-width:375px) {
    .ApplyNowNav-h5 {
        font-size: 25px;
    }
}

@media (max-width:320px) {
    .ApplyNowNav-h5 {
        font-size: 20px;
    }
}