@font-face {
    font-family: Satoshi;
    src: url(../../../../public/Satoshi-Regular.otf);
}

.ContactUsNav-container {
    padding: 2% 3.2% 0%;
    overflow: hidden;
    width: 100%;
}

.ContactUsNav-h5 {
    color: var(--text-icon-light-body, #33353A);
    font-family: Satoshi;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-wrap: wrap;
}

.ContactUsNav-col-1 {
    width: 70%;
}

.ContactUsNav-col-2 {
    width: 30%;
    text-align: right;
}

.ContactUsNav-button {
    margin-top: 10px;
}

@media (max-width:1024px) {
    .ContactUsNav-h5 {
        font-size: 30px;
    }
}

@media (max-width:768px) {
    .ContactUsNav-h5 {
        font-size: 25px;
    }
}

@media (max-width:600px) {
    .ContactUsNav-col-1 {
        width: 100%;
    }

    .ContactUsNav-col-2 {
        width: 100%;
        text-align: left;
        margin-top: 3%;
    }

    .ContactUsNav-h5 {
        font-size: 25px;
    }
}

@media (max-width:425px) {
    .ContactUsNav-h5 {
        font-size: 25px;
    }
}

@media (max-width:375px) {
    .ContactUsNav-h5 {
        font-size: 25px;
    }
}