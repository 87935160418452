@font-face {
    font-family: Satoshi-light;
    src: url(../../../../public/Satoshi-Light.otf);
}

@font-face {
    font-family: Satoshi;
    src: url(../../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-med;
    src: url(../../../../public/Satoshi-Medium.otf);
}

.ContactUs-container {
    padding: 0% 5%;
    overflow: hidden;
    padding-top: 5%;
}

.contact-us-info {
    width: 45%;
}

.contact-us-info1 {
    width: 55%;
}

.contact-page-map {
    width: 100%;
    height: 15rem;
    border-radius: 10px;
    border: 1px solid #b9b4b4;
}

.contact-us-text-sec {
    display: flex;
    justify-content: center;
    gap: 5rem;
}

.ContactUs-h4 {
    color: var(--text-icon-light-heading, #131A27);
    font-family: Satoshi-med;
    font-size: 68px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    padding-top: 4.5%;
}

.ContactUs-h6 {
    color: var(--text-icon-light-heading, #131A27);
    font-family: Satoshi-med;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.ContactUs-p {
    color: var(--text-icon-light-body, #33353A);
    font-family: Satoshi;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.24px;
}

.ContactUs-Address-p {
    width: 80%;
}

@media (max-width:1440px) {
    .ContactUs-Address-p {
        width: 100%;
    }

    .ContactUs-p {
        font-size: 20px;
    }

    .ContactUs-h6 {
        font-size: 25px;
    }

    .ContactUs-h4 {
        font-size: 55px;
    }

    .contact-us-text-sec {
        gap: 2rem;
    }
}

@media (max-width:1024px) {
    .ContactUs-Address-p {
        width: 100%;
    }

    .ContactUs-p {
        font-size: 18px;
    }

    .ContactUs-h6 {
        font-size: 23px;
    }

    .ContactUs-h4 {
        font-size: 50px;
    }

    .contact-us-text-sec {
        display: block;
    }

    .contact-us-info,
    .contact-us-info1 {
        width: 100%;
    }
}

@media (max-width:924px) {
    .ContactUs-p {
        font-size: 16px;
    }

    .ContactUs-h6 {
        font-size: 22px;
    }

    .ContactUs-h4 {
        font-size: 40px;
    }

    .ContactUs-container {
        padding-left: 5%;
    }
}

@media (max-width:768px) {
    .ContactUs-container {
        padding-left: 5%;
    }
    .ContactUs-h4 {
        padding-top: 6%;
    }
}