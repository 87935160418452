@font-face {
    font-family: Satoshi-light;
    src: url(../../../../public/Satoshi-Light.otf);
}

@font-face {
    font-family: Satoshi;
    src: url(../../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-med;
    src: url(../../../../public/Satoshi-Medium.otf);
}

.CareersNav-container {
    border-top: 1px solid #98A2B3;
    padding: 0% 3.2%;
    padding-top: 115px;
    overflow: hidden;
    width: 100%;
}

.CareersBackground {
    text-align: center;
    padding-bottom: 40px;
}


.CareersNav-h5 {
    color: var(--text-icon-light-heading, #131A27);
    font-family: Satoshi-med;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    transition: 0.5s;
}

.CareersNav-h3 {
    color: var(--text-icon-light-body, #33353A);
    font-family: Satoshi-med;
    font-size: 96px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.CareersNav-container-Row {
    border-bottom: 1px solid #98A2B3;
    transition: 3s;
    cursor: pointer;
}

.CareersNav-container-row-col-1 {
    width: 63%;
    padding: 2% 0%;
}

.CareersNav-container-row-col-2 {
    width: 37%;
    border: 0px solid;
    height: 100px;
}

.CareersNav-container-row-col-1-row-col {
    width: 50%;
}

.CareersNav-Arrow {
    text-align: right;
    background-color: transparent;
}

.Arrow-Icon {
    content: url(../../../assets/AboutPage/ArrowIconInActive.png);
    transition: 2s;
    background-color: transparent;
}

.CareersNav-container-Row:hover .Arrow-Icon {
    content: url(../../../assets/AboutPage/ArrowIconActive.png);
}


.CareersNav-container-Row:active .Arrow-Icon {
    content: url(../../../assets/AboutPage/ArrowIconActive.png);
}

.CareersNav-container-Row:hover .CareersNav-h5 {
    padding-left: 5%;
}

@media (max-width:1540px) {
    .CareersNav-h5 {
        font-size: 35px;
    }
}

@media (max-width:1024px) {
    .CareersNav-h5 {
        font-size: 30px;
    }

    .CareersNav-h3 {
        font-size: 45px;
    }
}

@media (max-width:768px) {

    .CareersNav-h5 {
        font-size: 24px;
    }

    .Arrow-Icon {
        width: 100px;
        height: 100px;
    }

    .CareersNav-container-row-col-1 {
        width: 80%;
    }

    .CareersNav-container-row-col-2 {
        width: 20%;
    }

    .CareersNav-h3 {
        font-size: 40px;
    }
}

@media (max-width:600px) {

    .CareersNav-h5 {
        font-size: 25px;
        margin-top: 3%;
    }

    .Arrow-Icon {
        width: 100px;
        height: 100px;
    }

    .CareersNav-container-row-col-1-row-col {
        width: 100%;
    }

    .CareersNav-container {
        padding-top: 80px;
        padding: 0% 5.2%;

    }

    .CareersBackground {
        margin-top: 5%;
    }
}

@media (max-width:425px) {

    .CareersNav-h5 {
        font-size: 20px;
    }

    .Arrow-Icon {
        width: 60px;
        height: 60px;
    }

    .CareersNav-container {
        padding: 0% 5.2%;
    }

    .CareersNav-container-row-col-1 {
        width: 80%;
    }

    .CareersNav-container-row-col-2 {
        width: 20%;
    }

    .CareersNav-container {
        padding-top: 80px;
    }
}

@media (max-width:375px) {
    .CareersNav-container {
        padding: 0% 6%;
    }

    .CareersNav-h3 {
        margin-top: 25px;
    }
}