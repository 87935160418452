@font-face {
    font-family: Satoshi;
    src: url(../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-medium;
    src: url(../../../public/Satoshi-Medium.otf);
}

.video4 {
    width: 100%;
    height: auto;
    display: block;
}

.video1 {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.control-icon-div {
    position: absolute;
    margin-top: 6vh;
    right: 0%;
    background-color: transparent;
    display: none;
    z-index: 1;
}

.video-container:hover .control-icon-div {
    display: flex !important;
    gap: 1rem;
}

.control-icon {
    transform: translate(-50%, -50%);
    cursor: pointer;
    background-color: transparent;
}

.home-main-section {
    margin: 8rem 9rem;
    height: 65vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-main-heading {
    color: #131A27;
    text-align: center;
    font-family: Satoshi;
    font-size: calc(3rem + 3rem);
    line-height: 120%;
}

.home-main-section1 {
    display: none;
}

.home-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}

.home-main-heading,
.home-heading-video {
    margin: 0 10px;
}

.video-homepage {
    width: 100%;
    height: auto;
    display: block;
}

.video-container11 {
    width: 200px;
    height: 75px;
    border-radius: 50px;
    overflow: hidden;
    margin: 2rem 0px 28px;
}

.home-section-para {
    width: 65%;
    color: #131A27;
    text-align: center;
    font-family: Satoshi;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.24px;
    margin-top: 3rem;
}

.homepage-image {
    border-radius: 75px;
    margin-top: 10px;
    width: 120px;
    height: 75px;
}

/* Optionally, you can hide controls */
.video1::-webkit-media-controls,
.video4::-webkit-media-controls,
.video1::-webkit-media-controls-enclosure,
.video4::-webkit-media-controls-enclosure,
.video1::-webkit-media-controls-panel,
.video4::-webkit-media-controls-panel,
.video1::-webkit-media-controls-overlay-container,
.video4::-webkit-media-controls-overlay-container,
.video1::-webkit-media-controls-start-playback-button,
.video4::-webkit-media-controls-overlay-container,
.video-homepage::-webkit-media-controls-start-playback-button,
.video-homepage::-webkit-media-controls-start-playback-button {
    display: none !important;
    /* Hide the media controls */
}

/**       heading  Scrolling         */
.scroll-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    margin: 15rem 0px 0rem;
}

@keyframes scroll1 {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.vishesh-scroll-heading {
    color: #444444;
    font-family: Satoshi-medium;
    font-size: calc(4rem + 6vw);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin: 0;
}

.heading-scroll-img {
    width: 8rem;
    margin-top: -3rem;
}

@keyframes scroll2 {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@media only screen and (max-width: 1400px) {
    .home-main-section {
        margin: 8rem 3rem;
    }
}


@media only screen and (max-width: 1200px) {
    .video-container4 {
        width: 238px;
        height: 100px;
    }

    .home-main-section {
        margin: 2rem 0.5rem;
        height: auto;
    }

    .home-main-heading {
        font-size: calc(2rem + 2vw);
    }

    .home-section-para {
        width: 85%;
        margin-top: 1rem;
        font-size: large;
    }
}

@media only screen and (max-width: 1040px) {
    .scroll-container {
        margin: 8rem 0px;
    }
}

@media only screen and (max-width: 1000px) {
    .video1 {
        height: auto;
        object-fit: content;
    }
}

@media only screen and (max-width: 850px) {
    .home-main-section1 {
        display: block;
        text-align: center;
        margin: 2rem;
    }

    .home-main-section {
        display: none;
    }
    .home-section-para{
        width: 100%;
        font-family: Satoshi;
    }
}

@media only screen and (max-width: 825px) {
    .video-container4 {
        width: 200px;
        height: 80px;
    }

    .scroll-container {
        margin: 6rem 0px;
    }

    .heading-scroll-img {
        width: 3.8rem;
        margin-top: -25px;
    }

    .control-icon-div {
        margin-top: 2rem;
    }

    .control-icon {
        width: 2.5rem;
    }
}

@media only screen and (max-width: 650px) {
    .video-container4 {
        width: 200px;
        height: 80px;
    }
}

@media only screen and (max-width: 500px) {
    .vishesh-scroll-heading {
        font-size: calc(2rem + 2vw);
        margin: 0px;
    }

    .heading-scroll-img {
        width: 1.6rem;
        margin-top: -0.8vh;
    }

    .video1 {
        height: 27vh;
    }

    .home-main-section1 {
        margin: 2rem 1rem;
    }
    
    .home-section-para{
        font-size: large;
    }
    .home-main-heading{
        font-size: calc(1rem + 1.5vw);
        font-family: Satoshi-medium;
    }
}

@media only screen and (max-width: 400px) {
    .video1 {
        height: 25vh;
    }
}

@media only screen and (max-width: 374px) {
    .video1 {
        height: 22vh;
    }
}

@media only screen and (max-width: 324px) {
    .video1 {
        height: auto;
    }
}