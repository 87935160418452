.main-heading {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 3.5rem;
    height: 13.5vh;
}

.main-logo {
    width: 313px;
    margin: 1.5rem 0rem;
    background-color: transparent;
    cursor: pointer;
}

/* Responsive media queries */
@media only screen and (max-width: 1400px) {
    .main-logo {
        width: 250px;
    }  
}

@media only screen and (max-width: 768px) {
    .main-heading {
        margin: 0px 1rem;
        height: auto;
    }
}

@media only screen and (max-width: 550px) {
    .main-logo {
        width: 220px;
    }
}