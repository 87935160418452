@font-face {
    font-family: Satoshi;
    src: url(../../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-medium;
    src: url(../../../../public/Satoshi-Medium.otf);
}

.our-services-section {
    margin: 4rem 4rem 0rem;
}

.services-heading {
    color: #131A27;
    font-family: Satoshi-medium;
    font-size: calc(2rem + 1.9vw);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.service-text-our-sec{
    margin: 0.8rem 0rem 2.5rem;
}

.service-text-btn-sec {
    display: flex;
    gap: 20vh;
    justify-content: space-between;
}

.services-container {
    display: flex;
    justify-content: space-between;
    gap: 10rem;
    margin: 2.2rem 0rem;
}

.video-container1 {
    width: 729px;
    height: 300px;
    border-radius: 25px;
    overflow: hidden;
}

.service-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.video-container2 {
    width: 100%;
    margin-top: 0.5rem;
    display: none;
}

/* Optionally, you can hide controls */
.service-video::-webkit-media-controls,
.service-video2::-webkit-media-controls,
.service-video::-webkit-media-controls-enclosure,
.service-video2::-webkit-media-controls-enclosure,
.service-video::-webkit-media-controls-panel,
.service-video2::-webkit-media-controls-panel,
.service-video::-webkit-media-controls-overlay-container,
.service-video2::-webkit-media-controls-overlay-container,
.service-video::-webkit-media-controls-start-playback-button,
.service-video2::-webkit-media-controls-start-playback-button {
    display: none !important;
}

.text-container {
    flex: 1;
}

.services-hr {
    width: 100%;
    margin-left: auto;
    margin-right: 0;
}

.service-team-heading {
    color: #131A27;
    font-family: Satoshi-medium;
    font-size: calc(1.8rem + 2vh);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-top: -5px;
}

.service-team-text {
    color: #33353A;
    font-family: Satoshi;
    font-size: calc(0.6rem + 0.75vw);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.2px;
    margin-top: 1.2rem;
}


.service-video2 {
    width: 100%;
    border-radius: 16px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.home-service-cta,
.home-service-cta1 {
    border-radius: 14px;
    border: none;
    padding: 24px 40px 26px 40px;
    color: #FCFCFC;
    background-color: #101828;
    font-family: Satoshi;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.26px;
    margin-top: 15px;
}

.home-service-cta:hover,
.home-service-cta1:hover {
    border-radius: 24px;
    transition: 0.5s;
}

.home-service-cta {
    width: 13rem;
}

.home-service-cta1 {
    display: none;
}

/* Responsive media queries */

@media only screen and (max-width: 1600px) {
    .video-container1 {
        width: 550px;
        height: 250px;
    }

    .service-team-heading {
        font-size: calc(1.5rem + 1.5vh);
    }

    .service-text-btn-sec {
        gap: 10vh;
    }
    .services-container {
        gap: 5rem;
    }
    .home-service-cta {
        width: 10rem;
        font-size: large;
        padding: 24px 33px 26px 33px;
    }
    
}

@media only screen and (max-width: 1175px) {
    .services-container {
        gap: 5rem;
    }

    .video-container1 {
        width: 400px;
        gap: 2rem;
        height: 250px;
    }
}

@media only screen and (max-width: 1040px) {
    .service-team-heading {
        font-size: calc(1.2rem + 1.2vh);
    }

    .our-services-section {
        margin: 5rem 2rem;
    }

}

@media only screen and (max-width: 800px) {
    .video-container1 {
        width: 370px;
    }

    .service-team-heading {
        font-size: calc(1.2rem + 1vh);
    }

    .our-services-section {
        margin: 3rem 1rem 5rem;
    }
}

@media only screen and (max-width: 750px) {
    .video-container1 {
        display: none;
    }

    .video-container2 {
        display: block;
    }

    .home-service-cta {
        display: none;
    }
.services-container{
    margin: 1rem 0rem;
}
    .home-service-cta1 {
        display: block;
        padding: 25px 33px 27px 33px;
        font-size: large;
    }

    .services-hr {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .service-team-text {
        font-size: calc(1rem + 0.5vw);
        margin-top: 0rem;
    }

    .service-text-btn-sec {
        display: block;
        margin-bottom: 0rem;
    }

    .services-heading {
        margin-bottom: 1rem;
    }
    .service-text-our-sec{
        margin-bottom: 1.5rem;
    }
}

@media only screen and (max-width: 650px) {
    .home-service-cta1 {
        padding: 20px 20px 22px 20px;
        width: 8rem;
    }
}
