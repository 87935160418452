@font-face {
    font-family: Satoshi;
    src: url(../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-medium;
    src: url(../../../public/Satoshi-Medium.otf);
}

.image-text-row {
    margin: 8.5rem 2.5rem 2rem 3.5rem;
}

.image-text-row {
    position: relative;
    height: 250px;
}

.blog-image-sec {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
}

.image-text-row::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 0;
    border-radius: 16px;
}

.blog-title {
    background-color: transparent;
    position: absolute;
    bottom: 50px;
    left: 20px;
    color: #ffffff;
    font-family: Satoshi;
    font-size: calc(1.5rem + 1.2vw);
    line-height: 120%;
    width: 50%;
}

.bolg-page-text {
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: #ffffff;
}

.carousel-row {
    margin-right: 0;
    background: #fff;
}

.carousel-container {
    display: flex;
    flex-direction: column;
    background: #fff;
}

.text-container {
    height: 100%;
    padding: 0rem 2.5% 1rem 0rem;
}

.titles-container {
    overflow-y: auto;
    padding-left: 1.1rem;
    margin-left: 20px;
    box-sizing: border-box;
    background: #fff;
    position: sticky;
    top: 0;
}

.content_heading {
    color: #131a27;
    font-family: Satoshi-medium;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.24px;
    background: #fff;
    margin-left: 15px;
}

.title-item {
    padding: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    background: #fff;
    color: #d0d5dd;
    font-family: Satoshi;
    font-size: 20px;
    letter-spacing: 0.2px;
}

.details-container {
    background: #fff;
    margin-bottom: 50px;
}

.active-details-container {
    opacity: 0.2;
}

.title-item.active {
    opacity: 1;
    color: #252525;
    font-family: Satoshi;
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 0.2px;
    text-decoration: "none";
}

.carousel-details {
    padding: 20px;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    background: #fff;
}

.description-item {
    opacity: 0;
    position: absolute;
    transition: opacity 0.3s ease-in-out;
    height: max-content;
    background: #fff;
}

.description-heading {
    color: #131a27;
    font-family: Satoshi-medium;
    font-size: 34px;
    line-height: 140%;
    background: #fff;
}

.description-item.active-description {
    opacity: 1;
    position: relative;
    height: auto;
    background: #fff;
}

.sub-description {
    color: #33353a;
    font-family: Satoshi;
    font-size: 24px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.24px;
    background: #fff;
}

.details-body {
    padding-top: 5rem;
    background: #fff
}

.item-list-sunpoint {
    color: #33353A;
    font-family: Satoshi;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.24px;
    background-color: transparent;
    margin: 1rem 0rem;
}

/* Responsive Design */
@media (max-width: 1444px) {
    .blog-title {
        font-size: 2.9em;
        width: 80%;
    }

    .blog-subtitle {
        font-size: 1.4em;
    }
}

@media (max-width: 1000px) {

    .image-text-row {
        margin: 0rem 1rem 2rem;
    }
}

@media (max-width: 900px) {
    .carousel-container {
        display: none;
    }

    .carousel-details {
        width: 100%;
        padding: 2rem;
    }

    .details-body {
        padding-top: 2rem;
    }
}

@media (max-width: 768px) {
    .blog-title {
        font-size: 1.5rem;
    }

    .blog-subtitle {
        font-size: 0.875rem;
    }

    .carousel-container {
        flex-direction: column;
    }

    .titles-container {
        border-right: none;
        border-bottom: 1px solid #ccc;
    }

    .item-list-sunpoint {
        font-size: 20px;
    }
}