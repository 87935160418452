@font-face {
    font-family: Satoshi;
    src: url(../../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-medium;
    src: url(../../../../public/Satoshi-Medium.otf);
}

.home-about-section {
    display: flex;
    justify-content: center;
    margin: 8rem 1rem 0rem 4rem;
}

.home-about-heading {
    color: #131A27;
    font-family: Satoshi-medium;
    font-size: calc(2rem + 1.9vw);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.home-about-section-css {
    margin-bottom: 1.5rem;
}

.home-about-para {
    width: 95%;
    font-family: Satoshi;
    font-size: calc(0.6rem + 0.75vw);
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.24px;
    color: #33353A;
}

.about-us-img1 {
    display: none;
}

.home-about-section-css1 {
    margin-top: 1.2rem;
}

.about-us-img {
    width: 50rem;
    border-radius: 32px;
}

.about-us-btn {
    border-radius: 14px;
    border: none;
    background: #101828;
    padding: 24px 40px 26px 40px;
    color: #FCFCFC;
    font-family: Satoshi;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.26px;
}

.about-us-btn:hover {
    border-radius: 24px;
    transition: 0.5s;
}

/* Responsive media queries */
@media only screen and (max-width: 1600px) {
    .about-us-img {
        width: 45rem;
        height: 32rem;
    }

    .about-us-btn {
        padding: 24px 33px 26px 33px;
        font-size: large;
    }
}

@media only screen and (max-width: 1200px) {
    .about-us-img {
        width: 40rem;
        height: 30rem;
    }
}

@media only screen and (max-width: 1040px) {
    .home-about-section {
        margin: 6rem auto 0rem 2rem;
    }

    .about-us-img {
        width: 30rem;
        height: 25rem;
    }
}

@media only screen and (max-width: 800px) {
    .home-about-section {
        margin: 6rem auto 0rem 1rem;
    }

    .about-us-img {
        width: 26rem;
        height: 25rem;
    }
}

@media only screen and (max-width: 725px) {
    .home-about-section {
        display: block;
    }

    .about-us-img {
        display: none;
    }

    .home-about-section-css1 {
        margin-top: 0.8rem;
    }

    .home-about-section-css2 {
        margin-top: 1.6rem;
    }


    .about-us-img1 {
        width: 96%;
        height: 100%;
        margin: 1rem 0rem;
        display: block;
        border-radius: 16px;
    }

    .home-about-para {
        font-size: calc(1rem + 0.5vw);
    }
}

@media only screen and (max-width: 650px) {
    .about-us-btn {
        padding: 18px 26px 20px 26px;
    }
}

@media only screen and (max-width: 500px) {
    .home-about-section {
        margin: 1rem auto 0rem 1rem;
    }

    .home-about-section-css1 {
        margin-top: 0rem;
    }

    .home-about-section-css2 {
        margin-top: 1rem;
    }

    .home-about-section-css {
        margin-bottom: 0rem;
    }
}