@font-face {
    font-family: Satoshi-light;
    src: url(../../../../public/Satoshi-Light.otf);
}

@font-face {
    font-family: Satoshi;
    src: url(../../../../public/Satoshi-Regular.otf);
}

@font-face {
    font-family: Satoshi-med;
    src: url(../../../../public/Satoshi-Medium.otf);
}

.principlesp-container {
    padding: 0% 3.2%;
    max-width: 100%;
    margin: 0 auto;
}

.principles-Col-1 {
    width: 33%;
    border: 0px solid;
}

.principles-Col-2 {
    width: 33%;
    border: 0px solid;
    padding-left: 5%;
}

.principles-Col-3 {
    width: 33%;
    border: 0px solid;
    padding-left: 6%;
}

.principles-Row {
    justify-content: space-between;
    margin-top: 2%;
}

.principles {
    color: var(--text-icon-light-heading, #131A27);
    text-align: center;
    font-family: Satoshi-med;
    font-size: calc(2rem + 1.9vw);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.icon {
    transform: translate(-1vw, 0px);
    margin-bottom: 5%;
}

.heading-principals {
    color: var(--text-icon-light-heading, #131A27);
    font-family: Satoshi-med;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    max-width: 30vw;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
    .principles-Row {
        margin-top: 2%;
    }

    .principles-Col-1 {
        width: 100%;
        border: 0px solid;
        padding-left: 2%;
        text-align: center;
    }

    .principles-Col-2 {
        width: 100%;
        border: 0px solid;
        padding-left: 2%;
        text-align: center
    }

    .principles-Col-3 {
        width: 100%;
        border: 0px solid;
        padding-left: 2%;
        text-align: center
    }

    .heading-principals,
    .sub_text_principals {
        font-size: 5vw;
        text-align: center;
    }

    .principles-Row {
        justify-content: center;
        text-align: center;
    }
    .heading-principals {
        max-width: 100%;
    }

    .sub_text_principals {
        padding: 0% 10%;
    }
}

@media screen and (max-width: 576px) {

    .heading-principals,
    .sub_text_principals {
        font-size: 5vw;
    }

    .sub_text_principals {
        width: 100%;
        max-width: 100%;
    }

    .heading-principals {
        max-width: 100%;
    }

    .principles-Row {
        justify-content: center;
    }

    .principles-Col-1 {
        width: 100%;
        border: 0px solid;
        padding-left: 2%;
        text-align: center;
    }

    .principles-Col-2 {
        width: 100%;
        border: 0px solid;
        padding-left: 2%;
        text-align: center
    }

    .principles-Col-3 {
        width: 100%;
        border: 0px solid;
        padding-left: 2%;
        text-align: center
    }
}