.about-container {
    overflow: hidden;
    margin: 0rem 6rem 5rem 5rem;
}

.team-image {
    width: 100%;
    border-radius: 12px;
}

.about-image-carousel2 {
    display: flex;
    gap: 5px;
}

.about-image-carousel-section1 {
    width: 54.2%;
}

.about-image-carousel-section2 {
    width: 45%;
}

@media (max-width:1500px) {
    .about-container {
        margin: 0rem 4rem 5rem 3rem;
    }
}

@media (max-width:1024px) {
    .about-container {
        margin: 0rem 1rem;
    }
}

@media (max-width:768px) {
    .about-image-carousel-section1 {
        width: 53.5%;
    }

    .team-image {
        border-radius: 8px;
    }
}

@media (max-width:425px) {
    .about-image-carousel-section1 {
        width: 53%;
    }
}