.CreativeDesignTools-image {
    width: 25%;
    text-align: center;
    margin-bottom: 10%;
}

@media (max-width:500px) {
    .CreativeDesignTools-container {
        padding: 0%;
    }
}